<template>
	<section id="form-lista">
		<div class="form-lista-container">
			<form @submit.prevent>
				<div>
					<h2 class="form-lista-title">Title</h2>
					<div class="lista-title-container">
						<input
							@keydown.enter.prevent
							maxlength="15"
							type="text"
							placeholder="List title"
							v-model="nomeLista"
							:class="{ erro: !nomeLista && listaForm.length > 1 }"
						/>
					</div>
				</div>
				<div>
					<h2 class="form-lista-title">List</h2>
					<div
						v-for="(itemLista, index) in listaForm"
						:key="index"
						class="form-lista-item"
					>
						<p class="form-lista-index">{{ index + 1 }}</p>
						<input
							maxlength="7"
							@keydown.enter.prevent
							@keyup="
								limiteCaracteres(
									itemLista.kanji,
									index,
									'kanji',
									maxLengthKanji
								)
							"
							type="text"
							v-model="itemLista.kanji"
							:placeholder="index == 0 ? 'example: 日本' : '日本'"
							class="nihongo"
							:class="{
								erro: !itemLista.kanji && listaForm.length > index + 1,
							}"
						/>
						<input
							maxlength="14"
							@keydown.enter.prevent
							@keyup="
								limiteCaracteres(
									itemLista.hiragana,
									index,
									'hiragana',
									maxLengthHiragana
								)
							"
							type="text"
							v-model="itemLista.hiragana"
							:placeholder="index == 0 ? 'example: にほん' : 'にほん'"
							class="nihongo"
							:class="{
								erro: !itemLista.hiragana && listaForm.length > index + 1,
							}"
						/>
						<input
							maxlength="40"
							@keydown.enter.prevent
							@keydown.tab="novaLinha"
							@keyup="
								limiteCaracteres(
									itemLista.significado,
									index,
									'significado',
									maxLengthTraducao
								)
							"
							type="text"
							v-model="itemLista.significado"
							:placeholder="index == 0 ? 'example: Japan' : 'Japan'"
							:class="{
								erro: !itemLista.significado && listaForm.length > index + 1,
							}"
						/>
						<button
							tabindex="-1"
							class="form-lista-excluir"
							:disabled="excluirDisponivel"
							@click="apagarItem(index)"
						>
							<img
								src="@/assets/img/icon/icon-excluir-branco.svg"
								alt="Icon Delete"
							/>
						</button>
					</div>
				</div>
			</form>
			<div class="container-acoes-lista">
				<input
					id="upload-file"
					type="file"
					@change="onFileChange"
					accept=".xls, .xlsx"
				/>
				<label for="upload-file" class="btn-upload-file"
					><img src="@/assets/img/button/upload-file.svg" alt="Icon Add"
				/></label>
				<JsonExcel
					v-if="listaCarregada"
					class="btn-download-excel"
					:data="listaDownload"
					:name="fileNameNow()"
					><img
						src="@/assets/img/button/download-list.svg"
						alt="Download llist"
					/>
				</JsonExcel>
				<button
					class="btn-circle btn-criar-lista btn-adicionar-item-lista"
					:disabled="!listaNaoPreenchida"
					@click="adicionarItem"
				>
					<span
						><img src="@/assets/img/icon/icon-add.svg" alt="Icon Add"
					/></span>
				</button>
			</div>
			<div class="form-lista-container-btns">
				<button @click="voltar">Cancel</button>
				<button
					@click="enviarLista"
					:disabled="tituloNaoPreenchido || !listaNaoPreenchida"
				>
					{{ tituloBtn }}
				</button>
			</div>
		</div>
	</section>
</template>

<script>
	import JsonExcel from 'vue-json-excel';
	import XLSX from 'xlsx';
	import moment from 'moment';
	export default {
		name: 'FormLista',
		props: ['listaCarregada', 'tituloBtn'],
		components: {
			JsonExcel,
		},
		data() {
			return {
				nomeLista: '',
				listaForm: [{ kanji: '', hiragana: '', significado: '' }],
				listaFormClone: [],
				//
				file: null,
				//
				maxLengthKanji: 7,
				maxLengthHiragana: 14,
				maxLengthTraducao: 40,
			};
		},
		computed: {
			excluirDisponivel() {
				return this.listaForm.length < 2;
			},
			tituloNaoPreenchido() {
				return this.nomeLista == '';
			},
			listaNaoPreenchida() {
				return this.listaForm.every((element) => {
					return (
						element.kanji != '' &&
						element.hiragana != '' &&
						element.significado != ''
					);
				});
			},
			listaDownload() {
				return this.listaForm.map((item) => {
					return {
						Kanji: item.kanji,
						Hiragana: item.hiragana,
						Translation: item.significado,
					};
				});
			},
		},
		methods: {
			limiteCaracteres(text, index, item, limite) {
				if (text.length > limite) {
					return (this.listaForm[index][item] = this.listaForm[index][
						item
					].substring(0, limite));
				}
			},
			apagarItem(index) {
				if (!this.excluirDisponivel) {
					this.listaForm.splice(index, 1);
				}
			},
			novaLinha() {
				if (this.listaNaoPreenchida) {
					this.adicionarItem();
				}
			},
			adicionarItem() {
				this.listaForm.push({ kanji: '', hiragana: '', significado: '' });
			},
			voltar() {
				this.$router.push('/');
			},
			montarLista() {
				const data = this.listaForm.map((item, index) => {
					return {
						id: index + 1,
						kanji: String(item.kanji).substr(0, this.maxLengthKanji),
						hiragana: String(item.hiragana).substr(0, this.maxLengthHiragana),
						significado: String(item.significado).substr(
							0,
							this.maxLengthTraducao
						),
						acertou: false,
					};
				});
				return data;
			},
			enviarLista() {
				const data = {
					nomeLista: this.nomeLista,
					lista: this.montarLista(),
				};
				this.$emit('salvarLista', data);
			},
			carregarListaClone() {
				const listas = this.$store.state.user.listasClone;
				const key = this.$route.params.key;
				const lista = listas[key];
				this.listaFormClone = lista;
			},
			onFileChange(event) {
				this.file = event.target.files ? event.target.files[0] : null;
				if (this.file) {
					const reader = new FileReader();
					reader.onload = (e) => {
						const bstr = e.target.result;
						const wb = XLSX.read(bstr, { type: 'binary' });
						const wsname = wb.SheetNames[0];
						const ws = wb.Sheets[wsname];
						const data = XLSX.utils.sheet_to_json(ws, {
							defval: '',
						});
						this.adicionarExcel(data);
					};

					reader.readAsBinaryString(this.file);
				}
			},
			adicionarExcel(dadosExcel) {
				let data = [];
				const maxLengthKanji = this.maxLengthKanji;
				const maxLengthHiragana = this.maxLengthHiragana;
				const maxLengthTraducao = this.maxLengthTraducao;
				dadosExcel.map((item) => {
					data.push({
						kanji: item[Object.keys(item)[0]]
							? String(item[Object.keys(item)[0]]).substr(0, maxLengthKanji)
							: '',
						hiragana: item[Object.keys(item)[1]]
							? String(item[Object.keys(item)[1]]).substr(0, maxLengthHiragana)
							: '',
						significado: item[Object.keys(item)[2]]
							? String(item[Object.keys(item)[2]]).substr(0, maxLengthTraducao)
							: '',
					});
				});
				this.listaForm = data;
			},
			fileNameNow() {
				return `kanjianki-list_${this.nowDateString()}.xls`;
			},
			nowDateString() {
				var date = new Date();
				return moment(date).format('YYYY-MM-DD_HH:mm:ss');
			},
		},
		mounted() {
			if (this.listaCarregada) {
				this.nomeLista = this.listaCarregada.nomeLista;
				this.listaForm = this.listaCarregada.lista;
				this.carregarListaClone();
			}
		},
		beforeDestroy() {
			if (this.listaCarregada) {
				this.listaForm.length = 0;
				this.nomeLista = this.listaFormClone.nomeLista;
				this.listaFormClone.lista.map((item) => this.listaForm.push(item));
			}
		},
	};
</script>
