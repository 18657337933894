var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"form-lista"}},[_c('div',{staticClass:"form-lista-container"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('h2',{staticClass:"form-lista-title"},[_vm._v("Title")]),_c('div',{staticClass:"lista-title-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nomeLista),expression:"nomeLista"}],class:{ erro: !_vm.nomeLista && _vm.listaForm.length > 1 },attrs:{"maxlength":"15","type":"text","placeholder":"List title"},domProps:{"value":(_vm.nomeLista)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.nomeLista=$event.target.value}}})])]),_c('div',[_c('h2',{staticClass:"form-lista-title"},[_vm._v("List")]),_vm._l((_vm.listaForm),function(itemLista,index){return _c('div',{key:index,staticClass:"form-lista-item"},[_c('p',{staticClass:"form-lista-index"},[_vm._v(_vm._s(index + 1))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(itemLista.kanji),expression:"itemLista.kanji"}],staticClass:"nihongo",class:{
							erro: !itemLista.kanji && _vm.listaForm.length > index + 1,
						},attrs:{"maxlength":"7","type":"text","placeholder":index == 0 ? 'example: 日本' : '日本'},domProps:{"value":(itemLista.kanji)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"keyup":function($event){return _vm.limiteCaracteres(
								itemLista.kanji,
								index,
								'kanji',
								_vm.maxLengthKanji
							)},"input":function($event){if($event.target.composing){ return; }_vm.$set(itemLista, "kanji", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(itemLista.hiragana),expression:"itemLista.hiragana"}],staticClass:"nihongo",class:{
							erro: !itemLista.hiragana && _vm.listaForm.length > index + 1,
						},attrs:{"maxlength":"14","type":"text","placeholder":index == 0 ? 'example: にほん' : 'にほん'},domProps:{"value":(itemLista.hiragana)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"keyup":function($event){return _vm.limiteCaracteres(
								itemLista.hiragana,
								index,
								'hiragana',
								_vm.maxLengthHiragana
							)},"input":function($event){if($event.target.composing){ return; }_vm.$set(itemLista, "hiragana", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(itemLista.significado),expression:"itemLista.significado"}],class:{
							erro: !itemLista.significado && _vm.listaForm.length > index + 1,
						},attrs:{"maxlength":"40","type":"text","placeholder":index == 0 ? 'example: Japan' : 'Japan'},domProps:{"value":(itemLista.significado)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.novaLinha.apply(null, arguments)}],"keyup":function($event){return _vm.limiteCaracteres(
								itemLista.significado,
								index,
								'significado',
								_vm.maxLengthTraducao
							)},"input":function($event){if($event.target.composing){ return; }_vm.$set(itemLista, "significado", $event.target.value)}}}),_c('button',{staticClass:"form-lista-excluir",attrs:{"tabindex":"-1","disabled":_vm.excluirDisponivel},on:{"click":function($event){return _vm.apagarItem(index)}}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-excluir-branco.svg"),"alt":"Icon Delete"}})])])})],2)]),_c('div',{staticClass:"container-acoes-lista"},[_c('input',{attrs:{"id":"upload-file","type":"file","accept":".xls, .xlsx"},on:{"change":_vm.onFileChange}}),_vm._m(0),(_vm.listaCarregada)?_c('JsonExcel',{staticClass:"btn-download-excel",attrs:{"data":_vm.listaDownload,"name":_vm.fileNameNow()}},[_c('img',{attrs:{"src":require("@/assets/img/button/download-list.svg"),"alt":"Download llist"}})]):_vm._e(),_c('button',{staticClass:"btn-circle btn-criar-lista btn-adicionar-item-lista",attrs:{"disabled":!_vm.listaNaoPreenchida},on:{"click":_vm.adicionarItem}},[_vm._m(1)])],1),_c('div',{staticClass:"form-lista-container-btns"},[_c('button',{on:{"click":_vm.voltar}},[_vm._v("Cancel")]),_c('button',{attrs:{"disabled":_vm.tituloNaoPreenchido || !_vm.listaNaoPreenchida},on:{"click":_vm.enviarLista}},[_vm._v(" "+_vm._s(_vm.tituloBtn)+" ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"btn-upload-file",attrs:{"for":"upload-file"}},[_c('img',{attrs:{"src":require("@/assets/img/button/upload-file.svg"),"alt":"Icon Add"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-add.svg"),"alt":"Icon Add"}})])}]

export { render, staticRenderFns }